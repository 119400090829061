// extracted by mini-css-extract-plugin
export var arrow = "LocationMap__arrow__oGFao";
export var closeBtn = "LocationMap__closeBtn__fhisV";
export var column = "LocationMap__column__ahGxu";
export var flex = "LocationMap__flex__o23X9";
export var flexColumn = "LocationMap__flexColumn__ATdq9";
export var gap1 = "LocationMap__gap1__ryUPP";
export var gap2 = "LocationMap__gap2__l7G1W";
export var gap3 = "LocationMap__gap3__dMgZF";
export var gap4 = "LocationMap__gap4__siEss";
export var gap5 = "LocationMap__gap5__ACx4p";
export var infoWindow = "LocationMap__infoWindow__EDmhd";
export var mapContainer = "LocationMap__mapContainer__osYcl";
export var mapElement = "LocationMap__mapElement__t6tyH";
export var row = "LocationMap__row__gLW72";